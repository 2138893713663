<template>
  <div class="not-found">
    <h1>404 NOT FOUND, sorry, this page doesn't exist</h1>
  </div>
</template>

<script>
  export default {
    name: 'PageNotFound',
  }
</script>

<style lang="sass" scoped>
h1
  color: black

.not-found
  height: 80vh
  width: 100%
  display: flex
  justify-content: center
  align-items: center
</style>
